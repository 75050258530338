.cardsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .selected_card_container,
  .card_container {
    display: flex;
    flex-direction: column;
    width: 21%;
    height: auto;
    background: #fff;
    border-radius: 10.9701px;
    text-align: left;
    cursor: pointer;

    &:hover {
      // box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.1);
      .subhead {
        color: white;
        background: #4389fa;
        .periodbtn {
          background: white !important;
        }
        .price {
          color: white;
        }
        .period {
          color: white;
        }
        .unlockprem {
          color: white;
        }
      }
      .buynow {
        background: #4389fa;
        color: #ffffff;
      }
    }

    .subhead {
      width: 100%;
      // background: rgba(67, 137, 250, 0.05);
      padding: 20px;
      border-radius: 8px 8px 0px 0px;

      .periodbtn {
        border-radius: 13px;
        color: #261c4b;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        padding: 0.2rem 4.3rem 0.2rem 1rem;
        width: max-content;
      }

      .price {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #261c4b;
      }

      .period {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #606060;
      }

      .unlockprem {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #4389fa;
      }
    }
    .divider {
      border: 1.08333px solid rgba(38, 28, 75, 0.12);
      margin: 0px 0 25px;
    }

    .subheadSelected {
      width: 100%;
      background: #4389fa;
      padding: 20px;
      border-radius: 8px 8px 0px 0px;

      .periodbtn {
        background: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #261c4b;
        width: fit-content;
        padding: 4px 14px;
      }

      .price {
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 4px;
        line-height: 48px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #ffffff;
      }

      .period {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #ffffff;
      }

      .unlockprem {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #ffffff;
      }
    }

    .descbDiv {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }

      h4 {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #000000;
        margin-bottom: 12px;
      }
    }

    .descRow {
      display: flex;
    }

    .buynowdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buynow {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fefefe;
      background: #4389fa;
      border: 1px solid #4389fa;
      box-sizing: border-box;
      border-radius: 4px;
      justify-content: center;
      padding-top: 14px;
      padding-bottom: 14px;
      cursor: pointer;
      margin-bottom: 0px;
      border-radius: 0px 0px 11px 11px;
    }

    .buynowSelected {
      background: #4389fa;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fefefe;
      border: 1px solid #4389fa;
      box-sizing: border-box;
      border-radius: 4px;
      justify-content: center;
      padding-top: 14px;
      padding-bottom: 14px;
      cursor: pointer;
      margin-bottom: 0px;
      border-radius: 0px 0px 11px 11px;
    }
  }
  .selected_card_container {
    // box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.1);
    .subhead {
      color: white;
      background: #4389fa;
      .periodbtn {
        background: white !important;
        color: #000000;
      }
      .price {
        color: white;
      }
      .period {
        color: white;
      }
      .unlockprem {
        color: white;
      }
    }
    .buynow {
      background: #4389fa;
      color: #ffffff;
    }
  }
}
