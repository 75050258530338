.UnnlockChatBotTitle {
  background-color: white;
  border-radius: 16px;
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #4389fa;
  margin: 30px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.UnnlockChatBotBody {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 30px;
  input,
  textarea {
    border: 1px solid #dee5ff;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    margin-right: 10px;
    outline: none;
  }
  .headerTile {
    font-size: 18px;
    font-weight: 500;
    color: #4389fa;
    margin-bottom: 0px;
  }

  .card {
    background: #ffffff;
    border-radius: 16px;
    width: -webkit-fill-available;
    margin-bottom: 20px;
    padding: 20px;
  }

  .subCard {
    display: flex;
    // min-height: 54px;
    // max-height: 54px;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid rgba(67, 137, 250, 0.38);
    border-radius: 8px;
    margin-top: 13px;
    padding: 10px;
    cursor: pointer;
  }

  .subCardDisabled {
    display: flex;
    // min-height: 54px;
    // max-height: 54px;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid rgba(67, 137, 250, 0.38);
    border-radius: 8px;
    margin-top: 13px;
    padding: 10px;
    opacity: 0.5;
    cursor: pointer;
  }

  .subCardSelected {
    display: flex;
    // min-height: 54px;
    // max-height: 54px;
    justify-content: center;
    align-items: center;
    background: #4389fa;
    color: white;
    border-radius: 8px;
    margin-top: 16px;
    padding: 13px;
    cursor: pointer;
  }

  .cardTips {
    background: #ffffff;
    border-radius: 0px 0px 16px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  .UnnlockChatBot {
    // padding: 30px;
    display: flex;
    justify-content: space-between;
    height: 100%;

    .UnnlockChatBotWrapper {
      width: 100%;
      height: 100%;

      .UnnlockChatBotConatiner {
        background: #ffffff;
        border-radius: 16px;
        width: 100%;
        padding: 20px;
        height: 100%;

        .customeselectResume {
          //width: 49%;
          display: flex;
          //justify-content: space-between;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .questionInput {
          margin: 10px 0px;
          display: flex;
          justify-content: flex-end;
        }
      }
      h3 {
        color: #4389fa;
        background: #ffffff;
        border-radius: 16px;
        width: 100%;
        padding: 15px;
        margin-top: 20px;
      }
      .answerSection {
        background: #ffffff;
        border-radius: 16px;
        width: 100%;
        padding: 20px;
        //margin-top: 20px;
        height: auto;
        textarea {
          border: none;
          width: 100%;
          height: auto;
          outline: none;
        }
        .loader,
        .loader:before,
        .loader:after {
          width: 2px; /* update this to control the size */
          aspect-ratio: 0.5;
          display: grid;
          background: radial-gradient(#000000c0 68%, #0000 72%) center/100% 50%
            no-repeat;
          animation: load 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
          transform: translate(calc(var(--_s, 0) * 150%));
        }
        .loader:before,
        .loader:after {
          content: "";
          grid-area: 1/1;
        }
        .loader:before {
          --_s: -1;
        }
        .loader:after {
          --_s: 1;
        }

        @keyframes load {
          20% {
            background-position: top;
          }
          40% {
            background-position: bottom;
          }
          60% {
            background-position: center;
          }
        }
      }
    }
  }
  .UnnlockChatBotTipsWrapper {
    width: 25%;
    h3 {
      color: #4389fa;
      background: #ffffff;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      padding: 15px;
      text-align: center;
      margin-bottom: 0;
    }
    .UnnlockChatBotTips {
      background: #ffffff;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 20px;

      p {
        cursor: pointer;
        border: 1px solid #dee5ff;
        border-radius: 8px;
        padding: 10px;
      }
    }
  }
}
